<div class="profil-card">
  <div class="ico-card h-full centered">
    <p-skeleton *ngIf="!(profile$ | async)" height="5rem" width="100px"></p-skeleton>

    <a *ngIf="profile$ | async as profil" class="avatar mb-3" routerLink="/profile">
      <img [src]="(user$ | async)?.avatar ?? 'assets/icon/exempleAvatar.jpg'" alt=""
           class="w-full object-cover">
    </a>
    <p-skeleton *ngIf="!(profile$ | async)" class="mt-4" height="2rem" width="200px"></p-skeleton>
    <span *ngIf="profile$ | async as profile"
          class="ico-h6">{{ profile.user.firstName }} {{ profile.user.lastName }}</span>
    <p-skeleton *ngIf="!(profile$ | async)" class="mt-4" height="2rem" width="200px"></p-skeleton>

    <a *ngIf="(profile$ | async) && !!kycInfo.text" class="string-ligne first" routerLink="/profile/kyc">

      <img [src]="kycInfo.icon" alt="">
      <span [style]="{ color: kycInfo.textcolor }" class="valid">
        {{ kycInfo.text }}
      </span>


    </a>

    <!--    <div *ngIf="profile$ | async as profile" class="string-ligne " tooltipPosition="top" [pTooltip]="'ico.user.vip' | translate">-->
    <!--      <span class="valid" >VIP : {{ profile?.user?.vip}}</span>-->
    <!--    </div>-->


    <div class="summit-tokens basic-card-ico">
      <div class="bottom">

        <div class="top left-info">
          <app-summit-logo class="mr-2"></app-summit-logo>
          <div class="info w-full">
            <span
              [innerHTML]="'ico.user.summitToken' | translate"
              class="summit justify-content-between w-full flex">
            </span>
            <p-skeleton *ngIf="!(profile$ | async)" class="mt-4" height="1rem" width="50px"></p-skeleton>

            <span *ngIf="profile$ | async as profile" class="ico-h6">{{
              summitToken | FixedPipe | customNumber:'2':'0'
              }}</span>
          </div>
        </div>
        <div class="top left-info">
          <div class="info w-full">
            <span
              [innerHTML]="'ico.user.credits' | translate"
              class="summit justify-content-between w-full flex">
            </span>
            <p-skeleton *ngIf="!(profile$ | async)" class="mt-4" height="1rem" width="50px"></p-skeleton>

            <span *ngIf="profile$ | async as profile"
                  class="ico-h6">{{ credits | FixedPipe | customNumber:'2':'0':'€'}}</span>
            <!-- <span *ngIf="profile$ | async as profile" class="ico-h6">{{
              averagePrice | customNumber:'3':'0':'€'
            }}</span> -->
          </div>
        </div>
      </div>

      <!-- <div class="divid-horizontal"></div> -->

      <!-- <div class="bottom">
        <div class="top left-info">
          <div class="logo">
            <div class="flex flex-column">
              <img alt="" class="summit-logo" src="../../../../assets/currencies/euro.png">
            </div>
          </div>
          <div class="info w-full">
            <span
            [innerHTML]="'ico.user.totalEur' | translate"
            class="summit justify-content-between w-full flex">
          </span>
          <p-skeleton *ngIf="!(profile$ | async)" class="mt-4" height="1rem" width="50px"></p-skeleton>

          <span *ngIf="profile$ | async as profile" class="ico-h6">{{
            amountInvestedEuro | FixedPipe | customNumber:'2':'0':'€'
          }}</span>
          </div>
        </div>

        <div class="top left-info">
          <div class="info w-full">
            <span
            [innerHTML]="'ico.user.credits' | translate"
            class="summit justify-content-between w-full flex">
            </span>
            <p-skeleton *ngIf="!(profile$ | async)" class="mt-4" height="1rem" width="50px"></p-skeleton>

            <span *ngIf="profile$ | async as profile"
          class="ico-h6">{{ credits | FixedPipe | customNumber:'2':'0':'€'}}</span>
          </div>
        </div>
      </div> -->
      <div class="actions">
        <div class=" flex flex-column flex-1 justify-content-between pr-4">
          <app-ico-btn *ngIf="!isInvestPage" [text]="'menu.ico.investir'" routerLink="./../invest"></app-ico-btn>
        </div>
        <div class=" flex flex-column justify-content-end flex-1" *ngIf="profile$ | async as profile">
          <!--          <div class="prices">-->
          <!--            <div class="flex align-items-center justify-content-between">-->
          <!--              <span [innerHTML]="'ico.user.tokenBonus' | translate" class="label"></span>-->
          <!--              <app-ico-info [pTooltip]="'ico.user.tokenBonusDesc' | translate"-->
          <!--                            [tooltipPosition]="'left'"></app-ico-info>-->
          <!--            </div>-->

          <!--            <p-skeleton *ngIf="!(profile$ | async) && !(activeRound$ | async)" class="mt-4" height="1rem"-->
          <!--                        width="50px"></p-skeleton>-->
          <!--            <ng-container *ngIf="activeRound$ | async as activeRound">-->
          <!--                        <span *ngIf="profile$ | async as profile"-->
          <!--                              class="ico-h6">{{ profile.gift | customNumber: '0': '0'}}</span>-->
          <!--            </ng-container>-->
          <!--          </div>-->

          <app-ico-btn [outline]="true" [text]="'Common.transactions'" routerLink="./../transactions"></app-ico-btn>

        </div>
      </div>
    </div>
    <div class="sponsorship basic-card-ico">
      <div class="top">
        <div class="info">
          <div
            [innerHTML]="'ico.user.referal' | translate"
            class="ico-h6"
          ></div>
        </div>
      </div>
      <div class="divid-horizontal"></div>
      <ng-container *ngIf="referral$ | async as referrals">
        <div class="progress-ctn">
          <div class="w-full flex align-items-center justify-content-between" *ngIf="!!referrals.step">
            <span>Niveau {{referrals.step.name}}</span>
            <span>{{ referrals.step.name}}/{{ referrals.targetStep.name}}</span>
          </div>
          <div class="progress" *ngIf="!!referrals.step">
            <div class="overlay"
                 [style.width.%]="(referrals.step.condition /referrals.targetStep.condition) * 100"></div>
          </div>
        </div>
        <ng-container *ngFor="let referral of referrals.referrals; let index = index;">
          <div class="actions justify-content-start">
            <div class="prices">
              <div class="flex align-items-center gap-1">
                <span
                  [innerHTML]="'ico.user.waiting' | translate"
                  class="label"
                ></span>
                <app-ico-info [text]="'ico.user.waitingTooltip' | translate"></app-ico-info>
              </div>
              <span class="ico-h6">{{!!referral.pending ? referral.pending : 0  }}</span>
            </div>

            <div class="prices">
              <div class="flex align-items-center gap-1">

          <span
            [innerHTML]="'ico.user.validate' | translate"
            class="label"
          ></span>
              <app-ico-info [text]="'ico.user.validateTooltip' | translate"></app-ico-info>
              </div>
              <span class="ico-h6">{{ !!referral.validate ? referral.validate : 0 }}</span>
            </div>
          </div>
          <ng-container *ngIf="profile$ | async as profile">
            <div class="sponsor-actions">
              <span
                class="xmall-txt w-full mb-2 justify-content-start align-items-start">{{ 'ico.user.referalTxtCode' | translate}}</span>
              <div
                class="input-sponsor cursor-pointer">
                <span>{{ profile.user.referralsCodes[index].code}}</span>

                <app-copy [text]="getLink(profile.user.referralsCodes[index].code)"></app-copy>
              </div>
            </div>
          </ng-container>
        </ng-container>

      </ng-container>
      <div class="sponsor-actions">
        <app-ico-btn
          [text]="'ico.user.referalTxt' | translate"
          class="w-full"
          routerLink="./../referral"
        ></app-ico-btn>
      </div>


    </div>
  </div>
</div>
