import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {GetProfile, ReadAllNotificaiton} from "@profile/stores/profile/profile.actions";
import {ProfileService} from "@profile/services/profile.service";
import {IProfile, IProfileStateModel, IStep} from "@models/dist";
import {GetAirdrop} from "@shared/stores/airdrop/airdrop.actions";

@State<IProfileStateModel>({
  name: 'ProfilState',
  defaults: {
    profile: null,
    referrals: [],
    amount: 0,
    step: null,
    targetStep: null,
    unreadNotifications: 0,
    conversations: 0,
  },
})

@Injectable()
export class ProfileState implements NgxsOnInit {

  constructor(private profileService: ProfileService) {
  }

  @Selector()
  static hasUnread(state: IProfileStateModel): number | null {
    return state.unreadNotifications;
  }

  @Selector()
  static hasConversations(state: IProfileStateModel): number | null {
    return state.conversations;
  }

  @Selector()
  static getProfile(state: IProfileStateModel): IProfile | null {
    return state.profile;
  }

  @Selector()
  static getReferral(state: IProfileStateModel): {
    step: IStep | null,
    targetStep: IStep | null,
    referrals: { pending: number, validate: number, }[],
    amount: number
  } | null {
    return {referrals: state.referrals, amount: state.amount, step: state.step, targetStep: state.targetStep};
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
  }

  @Action(GetProfile)
  async getProfile(ctx: StateContext<IProfileStateModel>) {
    try {
      const profile = await this.profileService.getProfile() as IProfile;
      const referral = await this.profileService.getReferral() as {
        step: { current: IStep, targetStep: IStep },
        referrals: { pending: number, validate: number, }[],
        amount: number
      };
      ctx.dispatch(new GetAirdrop());
      ctx.patchState({
        profile: profile,
        referrals: referral.referrals,
        amount: referral.amount,
        step: referral.step.current,
        targetStep: referral.step.targetStep,
        unreadNotifications: profile.unread,
        conversations: profile.conversations
      })
    } catch (e) {
      console.log(e);
    }
  }

  @Action(ReadAllNotificaiton)
  async readAllNotification(ctx: StateContext<IProfileStateModel>) {
    console.log('triger');
    const profile = await this.profileService.getProfile() as IProfile;

    ctx.patchState({
      unreadNotifications: profile.unread,
    })
  }


}
