export class GetProfile {
  static readonly type = '[ico] [profil] get profile data';
}

export class ReadAllNotificaiton{
  static readonly type = '[user] [profil] read all notification';

}


