<div class="">
  <app-ico-top [nohelp]="true" [title]="'ico.top.profil' | translate"></app-ico-top>

  <p-card class="mt-3">
    <div class="userSettings relative">
      <app-user-details [edit]="true"></app-user-details>

      <div class="surface-section mt-6" *ngIf="!user?.discord_id">
        <!--        <div class="title" [innerHTML]="'discord' | translate">-->

        <!--        </div>-->


        <div class="bannerMsg error mt-4">
          <div class="img">
            <img src="/assets/icon/twofa.svg"/>
          </div>
          <div class="msg">
            <div class="title flex gap-2">
              {{ "discordProfile.title" | translate }}
              <app-ico-info [placement]="'right'" [text]="'discordProfile.moreInfo' | translate"></app-ico-info>
            </div>
            <div
              [innerHtml]="'discordProfile.content' | translate"
              class="msg"
            ></div>
            <div
              (click)="discordConnect()"
              class="link">
              {{ "discordProfile.button" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="surface-section mt-6">
        <div [innerHTML]="'personal-data' | translate" class="title"></div>

        <p-skeleton *ngIf="!user" height="2rem" styleClass="mt-3" width="100%"></p-skeleton>
        <p-skeleton *ngIf="!user" height="2rem" styleClass="mt-3" width="100%"></p-skeleton>
        <p-skeleton *ngIf="!user" height="2rem" styleClass="mt-3" width="100%"></p-skeleton>
        <ng-container *ngIf="!!form">
          <div *ngIf="user" [formGroup]="form" class="form">
            <div class="form-group gap-3 " [class.id-group]="!!user?.discord_username">
              <div class="flex flex-column flex-1">
                <span class="simple-text">{{ 'internalId' | translate }}</span>
                <input class=" disabled" disabled="disabled" placeholder="{{ 'internalId' | translate }}" type="text"
                       value="{{ user.internalId}}">
              </div>
              <div class="flex flex-column flex-1 relative" *ngIf="!!user?.discord_username">
                <span class="simple-text">{{ 'discordUsername' | translate }}</span>
                <input class=" disabled" disabled="disabled" placeholder="{{ 'discordUsername' | translate }}"
                       type="text"
                       value="{{ user.discord_username}}">
                <a (click)="discordConnect()"
                   class="link discord-sublink">
                  {{ 'discordRelinkLink' | translate }}
                </a>
              </div>
            </div>
            <div class="form-group gap-3" *ngIf="user.accountType === EAccountType.COMPANY">

              <div class="flex flex-column flex-1">
                <span class="simple-text">{{ 'compagnyName' | translate }}</span>
                <input class="input disabled " disabled="disabled" placeholder="{{ 'compagnyName' | translate }}"
                       type="text"
                       value="{{ user.companyName}}">
              </div>
            </div>
            <div class="form-group gap-3">

              <div class="flex flex-column flex-1">
                <span class="simple-text">{{ 'firstName' | translate }}</span>
                <input class="input disabled " disabled="disabled" placeholder="{{ 'firstName' | translate }}"
                       type="text"
                       value="{{ user.firstName}}">
              </div>
              <div class="flex flex-column flex-1">
                <span class="simple-text">{{ 'lastName' | translate }}</span>
                <input class="input disabled" disabled="disabled" placeholder="{{ 'lastName' | translate }}"
                       type="text"
                       value="{{ user.lastName}}">
              </div>

            </div>
            <div class="form-group gap-3 flex flex-column">
              <div class="flex flex-column flex-1">
                <span class="simple-text">{{ 'Common.phonenumber' | translate }}</span>
                <input class="input disabled tel"
                       disabled
                       placeholder="{{ 'Common.phonenumber' | translate }}" type="text"
                       value="{{ user.phoneNumber}}">
                <a (click)="openPhoneModal()"
                   class="link">
                  <ng-container *ngIf="user.phoneNumber">
                    {{ 'Common.updatePhoneNumber' | translate }}
                  </ng-container>
                  <ng-container *ngIf="!user.phoneNumber">
                    {{ 'Common.setPhoneNumber' | translate }}
                  </ng-container>
                </a>
              </div>
            </div>

            <div class="form-group gap-3">
              <div class="flex flex-column flex-1">
                <span class="simple-text">{{ 'email' | translate }}</span>
                <input class=" disabled" disabled="disabled" placeholder="{{ 'email' | translate }}" type="text"
                       value="{{ user.email}}">
              </div>
              <div class="flex flex-column flex-1">
                <span class="simple-text">{{ 'pseudo' | translate }}</span>
                <input #pseudo class="input" formControlName="pseudo"
                       placeholder="{{ 'pseudo' | translate }}" type="text">
              </div>
            </div>
            <div class="form-group gap-3">
              <div class="flex flex-column flex-1">
                <span class="simple-text">{{ 'referralCodeRegister' | translate }}</span>
                <input class=" input"
                       [class.disabled]="!!user.signupReferral" formControlName="referral"
                       placeholder="{{ 'referralCode' | translate }}" type="text">
              </div>
            </div>
            <div class="form-group gap-3">
              <div class="flex flex-column flex-1">
                <span class="simple-text" [innerHTML]="'preferredService' | translate"></span>
                <div class="choices">
                  <div class="choice active" [class.active]="service === 'ico'" (click)="service = 'ico'">
                    <img src="/assets/logo/smt.svg" alt="">
                    {{ 'onboarding.ico' | translate }}
                  </div>
                  <div class="choice" [class.active]="service === 'research'" (click)="service = 'research'">
                    <img src="/assets/logo/research.svg" alt="">
                    {{ 'onboarding.research' | translate }}
                  </div>
                  <div class="choice" [class.active]="service === 'mining/home/dashboard'" (click)="service = 'mining/home/dashboard'">
                    <img src="/assets/logo/mining.svg" alt="">
                    {{ 'onboarding.mining' | translate }}
                  </div>
                  <div class="choice" [class.active]="service === 'gravity/dashboard'" (click)="service = 'gravity/dashboard'">
                    <img src="/assets/logo/gravity.svg" alt="">
                    {{ 'onboarding.gravity' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex align-items-center gap-4">
              <app-ico-btn (click)="update()" [text]="'update' | translate" class="flex flex-1"></app-ico-btn>
              <!--            <app-ico-btn class="flex flex-1" [outline]="true"  *ngIf="!user.phoneNumber" [text]="'update' | translate" (click)="update()"></app-ico-btn>-->
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </p-card>
</div>
