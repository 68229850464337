import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {PasswordModule} from "primeng/password";
import {CheckboxModule} from "primeng/checkbox";
import {InputTextModule} from "primeng/inputtext";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ImageModule} from "primeng/image";
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from "primeng/divider";
import {TagModule} from "primeng/tag";
import {CardModule} from 'primeng/card';
import {StepsModule} from 'primeng/steps';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DropdownModule} from 'primeng/dropdown';
import {TabViewModule} from 'primeng/tabview';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {PanelModule} from 'primeng/panel';
import {RadioButtonModule} from "primeng/radiobutton";
import {CodeInputModule} from 'angular-code-input';
import {SERVICES} from "@shared/services";
import {COMPONENTS, InfoComponent} from "@shared/components";
import {PIPES} from "@shared/pipes";
import {DIRECTIVES} from "@shared/directives";
import {SkeletonModule} from 'primeng/skeleton';
import {ChipsModule} from 'primeng/chips';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FileUploadModule} from 'primeng/fileupload';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {SelectButtonModule} from "primeng/selectbutton";
import {WelcomeComponent} from './components/welcome/welcome.component';
import {RippleModule} from "primeng/ripple";
import {CopyComponent} from './components/copy/copy.component';
import {LogoutComponent} from './components/logout/logout.component';
import {TwofaTriggerComponent} from './components/twofa-trigger/twofa-trigger.component';
import {ResumeComponent} from './components/invest-modal/resume/resume.component';
import {ToStringPipe} from './pipes/to-string.pipe';
import {LastTransactionsComponent} from './components/last-transactions/last-transactions.component';
import {ResumeModalComponent} from './components/resume-modal/resume-modal.component';
import {
  CancelModalTransactionComponent
} from './components/cancel-modal-transaction/cancel-modal-transaction.component';
import {MultiSelectModule} from "primeng/multiselect";
import {KycModalComponent} from './components/kyc-modal/kyc-modal.component';
import {QRCodeModule} from "angularx-qrcode";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input-gg";
import { CountryDropdownComponent } from './components/country-dropdown/country-dropdown.component';
import { CheckComponent } from './components/check/check.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    CardModule,
    TranslateModule,
    ChipsModule,
    PasswordModule,
    CheckboxModule,
    InputTextModule,
    CodeInputModule,
    TabViewModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    ButtonModule,
    DynamicDialogModule,
    OverlayPanelModule,
    PanelModule,
    DialogModule,
    DropdownModule,
    ImageModule,
    FileUploadModule,
    StepsModule,
    CalendarModule,
    DividerModule,
    TableModule,
    TagModule,
    RadioButtonModule,
    SkeletonModule,
    AutoCompleteModule,
    ClipboardModule,
    SelectButtonModule,
    QRCodeModule,
    RippleModule,
    MultiSelectModule,
    NgxIntlTelInputModule,
    NgxTippyModule
  ],
  declarations: [
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
    WelcomeComponent,
    CopyComponent,
    LogoutComponent,
    TwofaTriggerComponent,
    ResumeComponent,
    ToStringPipe,
    LastTransactionsComponent,
    ResumeModalComponent,
    CancelModalTransactionComponent,
    KycModalComponent,
    CountryDropdownComponent,
    CheckComponent
  ],
  providers: [
    ...SERVICES, // TODO vu qu'on utilise le providedIn root on a pas besoin d'injecter ici
    ...PIPES,
    QRCodeModule,
  ],
  exports: [
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
    QRCodeModule,
    CommonModule,
    RouterModule,
    PanelModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    TabViewModule,
    DropdownModule,
    CardModule,
    StepsModule,
    MessagesModule,
    MessageModule,
    DynamicDialogModule,
    PasswordModule,
    CheckboxModule,
    InputTextModule,
    DialogModule,
    ButtonModule,
    CodeInputModule,
    OverlayPanelModule,
    ImageModule,
    DividerModule,
    TagModule,
    RadioButtonModule,
    SkeletonModule,
    InfoComponent,
    ResumeComponent,
    MultiSelectModule,
    FileUploadModule,
    NgxTippyModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
}
